import AOS from 'aos';
import 'aos/dist/aos.css';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import "mburger-webcomponent";
import "mmenu-js";
Alpine.plugin(collapse)
window.Alpine = Alpine
Alpine.start()
import gsap from "gsap";
import Chart from 'chart.js/auto';
import ChartDeferredPlugin from 'chartjs-plugin-deferred';
Chart.register(ChartDeferredPlugin);
Chart.defaults.set('plugins.deferred', {
  xOffset: 150,  // Start the animation when the chart is 150px away from the viewport
  yOffset: '50%', // or the viewport's height in percentages
  delay: 500,     // Delay the animation start by 500 ms
  easing: 'easeInOutQuad', // Animation easing to use
  duration: 1000  // Duration of the animation in milliseconds
});
import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});


window.addEventListener("load", (event) => {
AOS.init({
   once: true,
   easing: 'ease-out-quad',
   duration: 600
});
});
// Code for Magnetic Buttons
window.onload = function() {
  var magnets = document.querySelectorAll('[data-magnetic]');
  var strength = 65;

  magnets.forEach((magnet) => {
    magnet.addEventListener('mousemove', moveMagnet);
    magnet.addEventListener('mouseout', function(event) {
      gsap.to(event.currentTarget, {
        duration: 1,
        x: 0,
        y: 0,
        ease: "power4.out"
      });
    });
  });

  function moveMagnet(event) {
    var magnetButton = event.currentTarget;
    var bounding = magnetButton.getBoundingClientRect();

    gsap.to(magnetButton, {
      duration: 1,
      x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
      y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength,
      ease: "power4.out"
    });
  }
}




// Make Chart.js and the initialization function globally accessible
window.Chart = Chart;

window.initializeChart = function (canvasId, config) {
  const canvas = document.getElementById(canvasId);
  if (!canvas) {
    console.error(`Canvas with ID '${canvasId}' not found.`);
    return;
  }
  const ctx = canvas.getContext('2d');
  new Chart(ctx, config);
};



